<!-- ProfileComponent.vue -->
<template>
    <div>
        <b-row align-h="between" class="my-1" align-v="">
            <h5>All User Profiles</h5>
            <b-button size="sm" @click="login()" variant="primary">Add</b-button>
        </b-row>

        <b-modal ref="passwordModal" :title="`${user}`" hide-footer>
            <b-form-group label="Password:" label-for="passwordInput">
                <b-form-input id="passwordInput" v-model="password" type="password" required></b-form-input>
            </b-form-group>

            <b-button @click="handlePasswordSubmit()" variant="primary">OK</b-button>

        </b-modal>
        <b-list-group>
            <b-list-group-item v-for="userProfile in userProfiles" :key="userProfile.user_id"
                :active="getUserSession().user == decodeURIComponent(userProfile.user_id)">
                <b-row class="d-flex align-items-center">
                    <b-avatar size="40" variant="primary" class="mr-3">{{ getInitials(userProfile.full_name) }}</b-avatar>
                    <span>{{ decodeURIComponent(userProfile.full_name) }}</span>
                    <b-col></b-col>
                    <div>
                        <b-button v-if="getUserSession().user != decodeURIComponent(userProfile.user_id)" size="sm"
                            @click="switchUser(userProfile.user_id)" variant="primary" class="mt-2">Activate
                            Profile</b-button>
                    </div>
                </b-row>
            </b-list-group-item>
        </b-list-group>
    </div>
</template>
  
<script>
import useJwt from '@/auth/jwt/useJwt'
export default {
    data() {
        return {
            userProfiles: [],
            password: "",
            user: "",
        };
    },
    mounted() {
        this.retrieveUserProfiles();
    },
    methods: {

        login() {
            this.$router.push({ name: 'auth-login' })
        },

        retrieveUserProfiles() {
            const storedUserProfiles = JSON.parse(localStorage.getItem('userProfiles')) || [];
            this.userProfiles = storedUserProfiles;
        },
        getInitials(name) {
            // Function to get initials from a full name
            const nameArray = name.split(' ');
            const initials = nameArray.map((word) => word[0]).join('').toUpperCase();
            return initials;
        },
        switchUser(userId) {
            const selectedUser = this.userProfiles.find((user) => user.user_id === userId);

            if (selectedUser) {
                this.user = decodeURIComponent(selectedUser.user_id)
                this.$refs.passwordModal.show();

            } else {
                console.error('User not found');
            }
        },
        handlePasswordSubmit() {
            this.loginApi(this.user, this.password)
        },
        loginApi(userEmail, password) {
            if (true) {
                    useJwt.login({
                        usr: userEmail,
                        pwd: password,
                    })
                        .then(response => {
                            const ability = [{ action: 'manage', subject: 'all' }]
                            const user = {}
                            user.full_name = response.data.full_name
                            user.email = userEmail
                            localStorage.setItem('userData', JSON.stringify(user))
                            this.user = ""
                                    this.password = "";
                                    this.$refs.passwordModal.hide();
                                    location.reload()

                        })
                        .catch(error => {
                            console.log(error)
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: 'Login Error',
                                    icon: 'XOctagonIcon',
                                    variant: 'danger',
                                    text: 'Invalid credentials please try again',
                                },
                            })
                        })
                }
        },

    },


};
</script>
  